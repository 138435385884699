import { useEffect, useState, useRef } from 'react';
import Head from 'next/head'
import Image from 'next/image'
import styles from "../styles/Pages/Home.module.scss"
import { BsPlayCircle, } from 'react-icons/bs';
import Slider from "react-slick";
import Link from 'next/link';
import Instagallery from "../components/Reusable/InstaGallery/Instagallery"
import { RidesAndActivitiesData } from "../data/RidesAndActivitiesData";
import { StoresData } from '../data/storesData';
import { gsap } from "gsap/dist/gsap";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import OurBlogs from '../components/Reusable/OurBlogs/OurBlogs';
import AutoPlayAudio from '../components/AudioPlayer/audio-player';
import PageViewPixel from '../components/Pixel/facebook/PageViewPixel';
import { AncorPrimaryButton, AncorSecoundaryButton, AncorTernaryButton } from '../components/Reusable/Buttons/Buttons';

var $ = require("jquery");
if (typeof window !== "undefined") {
  window.$ = window.jQuery = require("jquery");
}

export const getStaticProps = async () => {
  return {
    props: {
      Allattractions: RidesAndActivitiesData,
      AllStores: StoresData,
    }
  }
}

export default function Home({ Allattractions, AllStores }) {
  const [open, setOpen] = useState(false)
  const [isMute, setIsmute] = useState(false)
  const handleIsOpen = () => {
    setOpen((prev) => !prev);
  };

  const SnowKingdomGallery = [
    {
      img: '/images/snow-kingdom/snow-kingdom-4.png',
      title: 'snow-kingdom-4',
    },
    {
      img: '/images/snow-kingdom/snow-kingdom-1.png',
      title: 'snow-kingdom-1',
    },
    {
      img: '/images/snow-kingdom/snow-kingdom-2.png',
      title: 'snow-kingdom-2',
    },
    {
      img: '/images/snow-kingdom/snow-kingdom-3.png',
      title: 'snow-kingdom-3',
    },
    {
      img: '/images/snow-kingdom/snow-kingdom-5.png',
      title: 'snow-kingdom-5',
    },

  ]

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const bannersettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false
  };

  const snowKingDomSlider = {
    dots: true,
    infinite: true,
    speed: 1000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false
  }

  const handleClick = (e) => {
    gsap.registerPlugin(ScrollToPlugin);
    e.stopPropagation()
    gsap.to(window, {
      speed: 100,
      duration: 1,
      scrollTo: {
        y: "#firstsec",
        offsetY: 70,
      }
    })
  };

  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    function loadVideo() {
      window.YT.ready(function () {
        const youtubePlayer = new window.YT.Player('player', {
          height: '1180px',
          width: '1920px',
          videoId: 'wAn1MtgrA3E',
          playerVars: {
            autoplay: 1,
            mute: 1,
            loop: 1,
            playsinline: 1,
            modestbranding: 1,
            autohide: 1,
            showinfo: 0,
            rel: 0,
            controls: 0,
            playlist: 'wAn1MtgrA3E',
            cc_load_policy: 0,
            vq: 'hd720'
          },
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        });

        $('#mute').click(function () {
          if (youtubePlayer.isMuted()) {
            youtubePlayer.unMute();
            setIsMuted(false);
          } else {
            youtubePlayer.mute();
            setIsMuted(true);
          }
        });
      });
    }

    function onPlayerReady(event) {
      event.target.playVideo();
      event.target.setPlaybackQuality('hd720');
    }

    function onPlayerStateChange(event) {
      // Handle player state change if needed
    }

    if (open) {
      // Load the YouTube player script and initialize the player
      $.getScript('https://www.youtube.com/iframe_api', loadVideo);
    }
  }, [open]);

  const attractions = [
    {
      bgImage: "/images/attrac-img1.png",
    },
    {
      bgImage: "/images/attrac-img2.png",
    },
    {
      bgImage: "/images/attrac-img3.png",
    },
  ];

  useEffect(() => {
    const isLocalStorageCleared = localStorage.getItem('isLocalStorageCleared');

    if (!isLocalStorageCleared) {
      localStorage.clear();
      localStorage.setItem('isLocalStorageCleared', 'true');
    }
  }, []);

  return (
    <>
      <Head>
        <title>Adventure Resorts, Kufri | Best Amusement Park in Shimla</title>
        <meta name="description" content="Adventure Resorts, Kufri is the best destination for weddings, events, & adventure activities. Create cherished memories among the beauty of Shimla & Himachal Pradesh." />
        <meta name="keywords" content="Adventure Resorts Kufri,Adventure Park Kufri,Best Amusement Park,Adventure Park In Shimla,Adventure Resorts" />

        <meta property="og:title" content="Adventure Resorts, Kufri | Best Amusement Park in Shimla" />
        <meta property="og:description" content="Adventure Resorts, Kufri is the best destination for weddings, events, & adventure activities. Create cherished memories among the beauty of Shimla & Himachal Pradesh." />
        <meta property="og:image" content="https://www.adventureresortkufri.com/images/og-image.jpg" />
        <meta property="og:url" content="https://www.adventureresortkufri.com/" />
        <meta property="og:type" content="website" />

        <meta property="fb:app_id" content="673743864249347" />
        <link rel="icon" href="/favicon.png" />
        <link rel="canonical" href="https://www.adventureresortkufri.com/" />
      </Head>

      {!open ? (
        <main>
          <section className={styles.home_banner}>
            <div className={styles.banner_text}>
              <div className="container">
                <div className={`${styles.banner_text_con} common_title`}>
                  <h1 className="animate_title">A complete tourist destination</h1>
                  <div className="d-flex">
                    <button
                      className="common_btn"
                      style={{ zIndex: '2' }}
                      onClick={handleIsOpen}
                    >
                      Play Video <BsPlayCircle className="ps-2" size={28} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      ) : (
        <div className={styles.banner_iframe_video}>
          <div id="player"></div>
          <div className={styles.closeVideo} onClick={handleIsOpen}></div>
          <div className="container">
            <button
              id="mute"
              className={`${styles.pause_btn} common_btn`}
              aria-label={isMuted ? 'Unmute' : 'Mute'}
            >
              {isMuted ?
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <mask id="mask0_3057_259" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_3057_259)">
                    <path d="M19.8004 22.6L16.7754 19.575C16.3587 19.8417 15.9171 20.0708 15.4504 20.2625C14.9837 20.4542 14.5004 20.6083 14.0004 20.725V18.675C14.2337 18.5917 14.4629 18.5083 14.6879 18.425C14.9129 18.3417 15.1254 18.2417 15.3254 18.125L12.0004 14.8V20L7.00039 15H3.00039V8.99999H6.20039L1.40039 4.19999L2.80039 2.79999L21.2004 21.2L19.8004 22.6ZM19.6004 16.8L18.1504 15.35C18.4337 14.8333 18.6462 14.2917 18.7879 13.725C18.9296 13.1583 19.0004 12.575 19.0004 11.975C19.0004 10.4083 18.5421 9.00832 17.6254 7.77499C16.7087 6.54165 15.5004 5.70832 14.0004 5.27499V3.22499C16.0671 3.69165 17.7504 4.73749 19.0504 6.36249C20.3504 7.98749 21.0004 9.85832 21.0004 11.975C21.0004 12.8583 20.8796 13.7083 20.6379 14.525C20.3962 15.3417 20.0504 16.1 19.6004 16.8ZM16.2504 13.45L14.0004 11.2V7.94999C14.7837 8.31666 15.3962 8.86665 15.8379 9.59999C16.2796 10.3333 16.5004 11.1333 16.5004 12C16.5004 12.25 16.4796 12.4958 16.4379 12.7375C16.3962 12.9792 16.3337 13.2167 16.2504 13.45ZM12.0004 9.19999L9.40039 6.59999L12.0004 3.99999V9.19999ZM10.0004 15.15V12.8L8.20039 11H5.00039V13H7.85039L10.0004 15.15Z" fill="#1C1B1F" />
                  </g>
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <mask id="mask0_3057_265" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="28">
                    <rect x="0.955078" y="0.956482" width="26.087" height="26.087" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_3057_265)">
                    <path d="M16.1733 23.4837V21.2554C17.8038 20.7844 19.1172 19.8786 20.1135 18.538C21.1099 17.1974 21.6081 15.6757 21.6081 13.9728C21.6081 12.2699 21.1099 10.7481 20.1135 9.40757C19.1172 8.06699 17.8038 7.16119 16.1733 6.69018V4.46191C18.4197 4.96916 20.2494 6.10594 21.6625 7.87224C23.0755 9.63855 23.782 11.6721 23.782 13.9728C23.782 16.2735 23.0755 18.307 21.6625 20.0733C20.2494 21.8396 18.4197 22.9764 16.1733 23.4837ZM4.2168 17.2608V10.7391H8.56462L13.9994 5.30431V22.6956L8.56462 17.2608H4.2168ZM16.1733 18.3478V9.59778C17.0248 9.99633 17.6905 10.5942 18.1706 11.3913C18.6507 12.1884 18.8907 13.0579 18.8907 14C18.8907 14.9239 18.6507 15.7799 18.1706 16.5679C17.6905 17.3559 17.0248 17.9492 16.1733 18.3478ZM11.8255 10.576L9.48854 12.913H6.39071V15.0869H9.48854L11.8255 17.4239V10.576Z" fill="#1C1B1F" />
                  </g>
                </svg>
              }
            </button>
          </div>
        </div>
      )}

      {/* <AutoPlayAudio /> */}

      {/* {!open ? (
        <section
          className={styles.home_banner}
          style={{
            backgroundImage: 'url("/images/compressed-home-banner.jpg")',
          }}
        >
          <div className={styles.banner_text}>
            <div className="container">
              <div className={`${styles.banner_text_con} common_title`}>
                <h1 className='animate_title'>
                  A complete tourist destination
                </h1>
                <button className="common_btn" onClick={handleIsOpen}>Play Video <BsPlayCircle className='ps-2' size={28} /></button>
              </div>
            </div>
          </div>
          <div className={styles.banner_btn} onClick={handleClick}>
            <Image src='/icons/down-arrow.svg' height={40} width={40} alt='Down Icon'></Image>
          </div>
        </section>
      ) :
        <div className={styles.banner_iframe_video}>
          <div id='player'></div>
          <div className={styles.closeVideo} onClick={handleIsOpen}></div>
          <div className='container'>
            <button id='mute' className={`${styles.pause_btn} common_btn`}>Unmute</button>
          </div>
        </div>
      } */}

      {/* <div className={styles.banner_iframe_video}>
        <iframe src="https://www.youtube.com/embed/wAn1MtgrA3E?modestbranding=1&autohide=1&showinfo=0&rel=0&controls=0&autoplay=1&mute=1" id="vimeo-player" frameBorder="0" autoPlay allowFullScreen style={{ width: "1920px", height: "1180px", }} data-ready="true"></iframe>
      </div> */}

      <section className={`${styles.summer_special_offer} common_padding`}>
        <div className='container'>
          <Link href='/tickets' className={styles.summer_special_offer_img}>
            <Image src='/images/banners/monsoon-special-offer.png' alt='Monsoon Special offer at adventure resort' fill></Image>
          </Link>

          <Link href='/tickets' className={styles.summer_special_offer_img_mobile}>
            <Image src='/images/banners/monsoon-special-offer-mobile.png' alt='Monsoon Special offer at adventure resort' fill></Image>
          </Link>

          {/* <div className={styles.summer_special_offer_img}>
          <Image src='/images/banners/summer-special-offer.png' alt='Summer Special offer at adventure resort' fill></Image>
          </div> */}
        </div>
      </section>

      <section className={`${styles.our_rides_activities} common_padding`} id="firstsec">
        <div className='container'>
          <div className='common_title text-center'>
            <h2 id='revealUp'>Our Rides & Activities</h2>
          </div>
          <div className={styles.explore_btn}>
            <Link href='/rides-and-activities'>Explore More
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none">
                <path d="M1 0.5L5 4.5L1 8.5" stroke="#222222" strokeWidth="1.2" />
                <path d="M5 0.5L9 4.5L5 8.5" stroke="#222222" strokeWidth="1.2" />
              </svg>
            </Link>
          </div>
          <div className={styles.activities_slider}>
            <Slider {...settings} className="activity_slider">
              {Allattractions.slice(0, 6).map((activity, index) => (
                <div className={`${styles.activity_child} common_title`} key={index}>
                  <div className={styles.image_wrapper}>
                    {/* <img src={activity.bgImage} className="wrapimage" alt={activity.slug} /> */}
                    <Image src={activity.bgImage} className="wrapimage" alt={activity.slug} width={412} height={328}></Image>
                  </div>
                  <h3>{activity.title}</h3>
                  <p >{activity.description}</p>

                  <div className='d_flex flex_gap_10'>
                    <AncorPrimaryButton
                      redirectPage={'/tickets'}
                      className={'margin_0_auto w-50'}
                      buttonText={'Buy Tickets'}
                    />
                    <AncorTernaryButton
                      redirectPage={`/rides-and-activities/${activity.slug}`}
                      className={'margin_0_auto w-50'}
                      buttonText={'View More'}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/* <div className={styles.explore_btn}>
            <Link href='/rides-and-activities' className='common_btn'>Explore All</Link>
          </div> */}
          <div className={styles.explore_btn_mobile}>
            <Link href='/rides-and-activities'>Explore More
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none">
                <path d="M1 0.5L5 4.5L1 8.5" stroke="#222222" strokeWidth="1.2" />
                <path d="M5 0.5L9 4.5L5 8.5" stroke="#222222" strokeWidth="1.2" />
              </svg></Link>
          </div>
        </div>
      </section>

      <section className={`${styles.snow_kingdom}`}>
        <div className='container'>
          <div className={`${styles.snow_kingdom_main} row`}>
            <div className={`${styles.snow_kingdom_left} col-sm-12 col-md-7 `}>
              <Slider {...snowKingDomSlider} className='snow_kingdom_sec_slider'>
                {SnowKingdomGallery.map((item) => (
                  <Image src={item.img} alt={item.title} width={754} height={340} key={item.title}></Image>
                ))}
              </Slider>
            </div>
            <div className={`${styles.snow_kingdom_right} col-sm-12 col-md-6 common_title`}>
              <div className={`${styles.snow_kingdom_right_content} `}>
                <h2 className='animate_title'>Snow Kingdom Kufri</h2>
                {/* <Image src="/icons/line.svg" width={120} height={2} alt='Line Icon'></Image> */}
                <h3 id='revealUp'>Adventure Activities, Family Fun & More!</h3>
                <p>Shimla's adventure awaits! Snow Kingdom Kufri, Himachal's best snow park, offers year-round thrills. Conquer adult slides, whoosh down tube rides, or try classic sledding.  Kids have their own fun zone too! Build snowmen, have snowball fights, or simply be mesmerized by the snowy vistas. It's fun for families, couples & adventurers alike! Escape the ordinary - experience indoor snow park magic at Snow Kingdom Kufri. Discover things to do in Shimla you won't forget!</p>

                <AncorSecoundaryButton
                  redirectPage={'https://www.snowkingdomkufri.com'}
                  target="_blank"
                  className={'margin_0_auto'}
                  buttonText={'Explore'} />
                {/* <Link href="https://www.snowkingdomkufri.com" target='_blank' className='secoundary_btn'>EXPLORE</Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.snow_kingdom_mobile} common_padding`}>
        <div className='container'>
          <div className={`${styles.snow_kingdom_mobile_main} row`}>
            <div className={`${styles.snow_kingdom_left} col-12 `}>
              <Slider {...snowKingDomSlider} className='snow_kingdom_sec_slider'>
                {SnowKingdomGallery.map((item) => (
                  <Image src={item.img} alt={item.title} width={754} height={340} key={item.title}></Image>
                ))}
              </Slider>
            </div>
            <div className={`${styles.snow_kingdom_right} col-12 common_title`}>
              <div className={`${styles.snow_kingdom_right_content} `}>
                <h2 className='animate_title'>Snow Kingdom Kufri</h2>
                {/* <Image src="/icons/line.svg" width={120} height={2} alt='Line Icon'></Image> */}
                <h3 id='revealUp'>Adventure Activities, Family Fun & More!</h3>
                <p>Shimla's adventure awaits! Snow Kingdom Kufri, Himachal's best snow park, offers year-round thrills. Conquer adult slides, whoosh down tube rides, or try classic sledding.  Kids have their own fun zone too! Build snowmen, have snowball fights, or simply be mesmerized by the snowy vistas. It's fun for families, couples & adventurers alike! Escape the ordinary - experience indoor snow park magic at Snow Kingdom Kufri. Discover things to do in Shimla you won't forget!</p>
                <Link href="https://www.snowkingdomkufri.com" target='_blank' className='secoundary_btn'>EXPLORE</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.mettings_sec} bg_meeting_img`}>
        <div className={`${styles.mettings_main} `}>
          <div className='container'>
            <div className={`${styles.metting_child} `}>
              <div className={`${styles.metting_child_left} common_title`}>
                <h2>Book Your Special Day</h2>
                <p><strong>Ananta</strong> by Adventure Resorts</p>
                {/* <h3><strong>Ananta</strong> by Adventure Resorts</h3> */}
              </div>
              <div>
                {/* <Link href="https://ananta.adventureresortkufri.com" target="_blank" className='secoundary_btn'>EXPLORE</Link> */}
                <AncorSecoundaryButton
                  redirectPage={'https://ananta.adventureresortkufri.com'}
                  target="_blank"
                  className={'margin_0_auto'}
                  buttonText={'Explore'} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.restaurant_sec} common_padding`}>
        <div className='container'>
          <div className={`${styles.restasurant_main} row align-items-center justify-content-center`}>
            <div className={`${styles.restaurnat_left} col-md-6`}>
              <Image src='/images/restaurant-main.png' alt='Restaurant Image' width={725} height={550}></Image>
              {/* <img src='/images/restaurant-main.png' alt='Restaurant Image' /> */}
            </div>
            <div className={`${styles.restaurnat_right} col-md-4`}>
              <div className='common_title'>
                <h3 className='animate_title'>Dinning</h3>
                <h2 className='subhead_animate'>Wok On The Peak</h2>
                <p>Our restaurant has thoughtfully designed our menu to provide you with one-of-a-kind dining experiences that will take your palette on a voyage of flavor. In addition, we give you meals that are a blend of traditional and modern menu selection, demonstrating our culinary lineage while providing you with the greatest food offers.</p>
                <div className={styles.highlights}>
                  <div>
                    <Image src='/icons/food.svg' width={25} height={25} alt='Highlight Image'></Image>
                    <p>Quality Food</p>
                  </div>
                  <div>
                    <Image src='/icons/service.svg' width={25} height={25} alt='Highlight Image'></Image>
                    <p>Best Service</p>
                  </div>
                  <div>
                    <Image src='/icons/reviews.svg' width={25} height={25} alt='Highlight Image'></Image>
                    <p>Good Reviews</p>
                  </div>
                </div>
                <AncorSecoundaryButton
                  redirectPage={'/restaurant'}
                  className={'margin_0_auto'}
                  buttonText={'Explore'} />
                {/* <Link href='/restaurant' className='secoundary_btn'>Explore</Link> */}
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className={`${styles.stay_sec}`}>
        <div className='container'>
          <div className={`${styles.stay_main} row align-items-center justify-content-center`}>
            <div className={`${styles.stay_left} col-md-4`}>
              <div className='common_title'>
                <h3 className='animate_title'>Stay With Us</h3>
                <h2 className='subhead_animate'>The Twin Towers Hotel</h2>
                <p>Indulge in the warmth of nature when you have a restful vacation here with us and appreciate the great amenities. Our hotel is located in an area surrounded by stunning natural surroundings, and each of our rooms has a modern style that is guaranteed to make our visitors more comfortable throughout their stay.</p>
                <div className={styles.highlights}>
                  <div>
                    <Image src='/icons/room.svg' width={25} height={25} alt='Highlight Image'></Image>
                    <p>Luxury Rooms</p>
                  </div>
                  <div>
                    <Image src='/icons/bar.svg' width={25} height={25} alt='Highlight Image'></Image>
                    <p>Bar & Restaurant</p>
                  </div>
                  <div>
                    <Image src='/icons/wifi.svg' width={25} height={25} alt='Highlight Image'></Image>
                    <p>Free Wi-Fi</p>
                  </div>
                </div>
                <AncorSecoundaryButton
                  redirectPage={'https://www.hoteltwintowers.com'}
                  target="_blank"
                  className={'margin_0_auto'}
                  buttonText={'Book Your Stay'} />
              </div>
            </div>
            <div className={`${styles.stay_right} col-md-6`}>
              <Slider {...bannersettings} className="banner_slider">
                <div className={styles.stay_slider_child}>
                  {/* <img src='/images/stay.png' alt='Stay Imag' /> */}
                  <Image src='/images/stay.png' width={630} height={450} priority alt='Highlight Image'></Image>
                </div>
                <div className={styles.stay_slider_child}>
                  <Image src='/images/stay1.png' width={630} height={450} priority alt='Highlight Image'></Image>
                  {/* <img src='/images/stay1.png' alt='Stay Imag' /> */}
                </div>
                <div className={styles.stay_slider_child}>
                  <Image src='/images/stay2.png' width={630} height={450} priority alt='Highlight Image'></Image>
                  {/* <img src='/images/stay2.png' alt='Stay Imag' /> */}
                </div>
                {/* <div className={styles.stay_slider_child}>
                  <Image src='/images/stay3.png' width={630} height={450} priority alt='Highlight Image'></Image>
                </div> */}
                <div className={styles.stay_slider_child}>
                  <Image src='/images/stay4.png' width={630} height={450} priority alt='Highlight Image'></Image>
                  {/* <img src='/images/stay4.png' alt='Stay Imag' /> */}
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.our_specials} common_padding`}>
        <div className='container'>
          <div className={`${styles.our_specials_main} row`}>
            <div className={`${styles.our_specials_left} col-sm-12 col-md-6`}>
              <div className={`${styles.our_specials_left_child} common_title`}>
                <h2 className='animate_title'>Our Specials</h2>
                {/* <Image src="/icons/line.svg" width={120} height={2} alt='Line Icon'></Image> */}
                <h3 id='revealUp'>Air Charter Services</h3>
                <p>Enjoy premier air charter services with our top-notch helipads. We offer safe night parking, crew facilitation, barrel refueling, and fuel transportation. Experience seamless travel with unparalleled convenience and comfort. Book now for a hassle-free experience and enjoy the finest aviation services.</p>

                <AncorSecoundaryButton
                  redirectPage={'/helicopter-safari'}
                  className={'margin_0_auto'}
                  buttonText={'EXPLORE'} />
              </div>
            </div>
            <div className={`${styles.our_specials_right} col-sm-12 col-md-6`}>
              <Image src='/images/special.png' alt='Speciality Image' width={754} height={340}></Image>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.stores_sec} common_padding`}>
        <div className='container'>
          <div className={`${styles.store_main} row align-items-center`}>
            <div className={`${styles.store_left} col-md-6`}>
              <Image src='/images/store.png' width={626} height={375} priority alt='Store Image'></Image>
            </div>
            <div className={`${styles.store_right} col-md-6`}>
              <div className='common_title'>
                <h2 className='subhead_animate'>Stores in Adventure Resort</h2>
                <p>Adventure resorts stores are based on the idea of an experience, which is why they focus on providing their guests with opportunities to explore the outdoors. This is a great way to inspire people to get out and do something new. The Adventure resorts stores offer a wide range of souvenirs and other items that make for perfect gifts for loved ones back home.</p>
                <AncorSecoundaryButton
                  redirectPage={'/stores'}
                  className={'margin_0_auto'}
                  buttonText={'EXPLORE'} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.plan_sec} row`}>
        <div className={`${styles.plan_sec_left} col-md-4`}>
          <div className='col-md-8 common_title'>
            <h1 className='common_h1 plan_activity_head'>Plan Your Adventure Activities With Us</h1>
            {/* <Image src="/icons/line-white.svg" width={120} height={2} alt='Line Icon'></Image> */}
            <p>With over 20 activities, you may spend some quality time with your friends and family while having a great time at this place, which provides complete entertainment, amusement, food, and leisure.</p>
            <Link href="/rides-and-activities" className='ternary_btn'>Buy Tickets</Link>
          </div>
        </div>
        <div className={`${styles.plan_sec_right} col-md-8`}>
          <Image src="/images/activitiesimages/swinger-banner.jpg" sizes="100vw" fill priority alt='Plan Image'></Image>
        </div>
      </section>

      <OurBlogs />
      <Instagallery />
      <PageViewPixel />
    </>
  )
}


// <section className={`${styles.mettings_sec} bg_meeting_img`}>
//         {/* <div className='container'> */}
//         <div className={`${styles.mettings_main} `}>
//           <div className='container'>
//             <div className={`${styles.metting_child} `}>
//               <div className={`${styles.metting_child_left}  common_title mobile_text_center`}>
//                 <h2>Book Your Special Day</h2>
//                 {/* <Image src="/icons/line.svg" width={120} height={2} alt='Line Icon'></Image> */}
//                 <h3><strong>Ananta</strong> by Adventure Resorts</h3>
//                 {/* <p> Select our unmatched venue at Kufri for your meetings and events. Our skilled staff will work with you every step of the way to deliver the most perfect and remarkable event. You can also book us for wedding and making your big day even more special. Elevate your special day into a memorable and remarkable celebration by choosing a location.</p> */}
//                 <Link href="https://ananta.adventureresortkufri.com" target="_blank" className='secoundary_btn '>EXPLORE</Link>
//                 {/* <Link href="/" className='ternary_btn mx-3 '>EXPLORE</Link> */}
//               </div>
//               {/* <div className={`${styles.metting_child_right} col-md-6`}>
//                   <Image src="/images/metting-img.png" layout='fill' objectFit='cover' alt='Meeting Image'></Image>
//                   inside or making your day unforgettable in our lawns outdoor. Make your fantasies come true by planning a classic wedding with us.
//                 </div> */}
//             </div>
//           </div>
//         </div>
//         {/* </div> */}
//       </section>

// {!open ? (
//   <>
//     {/* // <Slider {...bannersettings} className="banner_slider"> */}
//     {/* <main>
//       <section
//         className={styles.home_booking_banner}
//       // style={{
//       //   backgroundImage: 'url("/images/main-banner.jpg")',
//       // }}
//       >
//         <Link href='/contact-us' className={styles.booking_banner_ancor}>
//           <img src='/images/main-banner.jpg' width="100%" height="100%" alt='Banner image Desktop' className={styles.desktop_first_banner} />
//           <img src='/images/mobile-main-banner.png' width="100%" height="100%" alt='Banner image Mobile' className={styles.mobile_first_banner} />
//         </Link>
//       </section>
//     </main> */}
//     <main>
//       <section
//         className={styles.home_banner}
//         style={{
//           // backgroundImage: 'url("/images/compressed-home-banner.jpg")',
//           // backgroundImage: 'url("/images/Christmas offer-desktop.gif")',
//         }}
//       >
//         {/* <Link href='/tickets' className={styles.booking_banner_ancor}></Link> */}
//         <div className={styles.banner_text}>
//           <div className="container">
//             <div className={`${styles.banner_text_con} common_title`}>
//               <h1 className='animate_title'>
//                 A complete tourist destination
//               </h1>
//               <div className='d-flex'>
//                 <button className="common_btn" style={{ zIndex: '2' }} onClick={handleIsOpen}>Play Video <BsPlayCircle className='ps-2' size={28} /></button>
//                 {/* <Link className="common_btn ms-3" href="/tickets">Buy Tickets</Link> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </main>
//     {/* <main>
//       <section
//         className={styles.home_booking_banner}
//         style={{
//           // backgroundImage: 'url("/images/ticketbooking-banner.png")',
//           backgroundImage: 'url("/images/winter-home-banner-2.png")'
//         }}
//       >
//         <Link href='/tickets' className={styles.booking_banner_ancor}></Link>
//         <div className={styles.banner_text}>
//         <div className="container">
//           <div className='d-flex'>
//             <Link className="common_btn ms-3" href="https://tickets.adventureresortkufri.com" style={{ zIndex: '2' }} onClick={handleIsOpen}>Buy Tickets</Link>
//           </div>
//         </div>
//       </div>
//       </section>
//     </main> */}
//     {/* </Slider> */}
//   </>
// ) :
//   <div className={styles.banner_iframe_video}>
//     <div id='player'></div>
//     <div className={styles.closeVideo} onClick={handleIsOpen}></div>
//     <div className='container'>
//       <button
//         id='mute'
//         className={`${styles.pause_btn} common_btn`}
//         aria-label={isMuted ? 'Unmute' : 'Mute'}
//       >
//         {isMuted ?
//           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
//             <mask id="mask0_3057_259" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
//               <rect width="24" height="24" fill="#D9D9D9" />
//             </mask>
//             <g mask="url(#mask0_3057_259)">
//               <path d="M19.8004 22.6L16.7754 19.575C16.3587 19.8417 15.9171 20.0708 15.4504 20.2625C14.9837 20.4542 14.5004 20.6083 14.0004 20.725V18.675C14.2337 18.5917 14.4629 18.5083 14.6879 18.425C14.9129 18.3417 15.1254 18.2417 15.3254 18.125L12.0004 14.8V20L7.00039 15H3.00039V8.99999H6.20039L1.40039 4.19999L2.80039 2.79999L21.2004 21.2L19.8004 22.6ZM19.6004 16.8L18.1504 15.35C18.4337 14.8333 18.6462 14.2917 18.7879 13.725C18.9296 13.1583 19.0004 12.575 19.0004 11.975C19.0004 10.4083 18.5421 9.00832 17.6254 7.77499C16.7087 6.54165 15.5004 5.70832 14.0004 5.27499V3.22499C16.0671 3.69165 17.7504 4.73749 19.0504 6.36249C20.3504 7.98749 21.0004 9.85832 21.0004 11.975C21.0004 12.8583 20.8796 13.7083 20.6379 14.525C20.3962 15.3417 20.0504 16.1 19.6004 16.8ZM16.2504 13.45L14.0004 11.2V7.94999C14.7837 8.31666 15.3962 8.86665 15.8379 9.59999C16.2796 10.3333 16.5004 11.1333 16.5004 12C16.5004 12.25 16.4796 12.4958 16.4379 12.7375C16.3962 12.9792 16.3337 13.2167 16.2504 13.45ZM12.0004 9.19999L9.40039 6.59999L12.0004 3.99999V9.19999ZM10.0004 15.15V12.8L8.20039 11H5.00039V13H7.85039L10.0004 15.15Z" fill="#1C1B1F" />
//             </g>
//           </svg>
//           :
//           <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
//             <mask id="mask0_3057_265" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="28">
//               <rect x="0.955078" y="0.956482" width="26.087" height="26.087" fill="#D9D9D9" />
//             </mask>
//             <g mask="url(#mask0_3057_265)">
//               <path d="M16.1733 23.4837V21.2554C17.8038 20.7844 19.1172 19.8786 20.1135 18.538C21.1099 17.1974 21.6081 15.6757 21.6081 13.9728C21.6081 12.2699 21.1099 10.7481 20.1135 9.40757C19.1172 8.06699 17.8038 7.16119 16.1733 6.69018V4.46191C18.4197 4.96916 20.2494 6.10594 21.6625 7.87224C23.0755 9.63855 23.782 11.6721 23.782 13.9728C23.782 16.2735 23.0755 18.307 21.6625 20.0733C20.2494 21.8396 18.4197 22.9764 16.1733 23.4837ZM4.2168 17.2608V10.7391H8.56462L13.9994 5.30431V22.6956L8.56462 17.2608H4.2168ZM16.1733 18.3478V9.59778C17.0248 9.99633 17.6905 10.5942 18.1706 11.3913C18.6507 12.1884 18.8907 13.0579 18.8907 14C18.8907 14.9239 18.6507 15.7799 18.1706 16.5679C17.6905 17.3559 17.0248 17.9492 16.1733 18.3478ZM11.8255 10.576L9.48854 12.913H6.39071V15.0869H9.48854L11.8255 17.4239V10.576Z" fill="#1C1B1F" />
//             </g>
//           </svg>
//         }
//       </button>
//     </div>
//   </div>
// }